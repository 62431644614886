<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">搜索栏</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">姓名</div>
					<div class="inputs_box">
						<el-input clearable="" type="text" placeholder="姓名" v-model="forms.name" />
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">证件号码</div>
					<div class="inputs_box">
						<el-input clearable="" type="text" placeholder="证件号码" v-model="forms.idNo" />
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">人员编号</div>
					<div class="inputs_box">
						<el-input clearable="" type="text" placeholder="人员编号" v-model="forms.socNo" />
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">时间范围</div>
					<div class="inputs_box">
						<el-date-picker clearable="" v-model="forms.month"  value-format="yyyy-MM-dd" type="month" :picker-options="pickerOptions" placeholder="选择月">
						</el-date-picker>
					</div>
				</div>
				<div class="search_flex">
					<div class="btn_ends" @click="handle_search">搜索</div>
				</div>
			</div>

		</div>
		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
				</div>
				<div class="rights">

				</div>
			</div>
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">
					<!-- 暂时无需 -->
					<!-- <el-table-column type="selection" width="55" label="序号">
					</el-table-column> -->
					<el-table-column prop="social_user.name" label="姓名" width=""></el-table-column>
					<el-table-column prop="social_user.idType" label="证件类型" width=""></el-table-column>
					<el-table-column prop="social_user.idNo" label="证件号码" width=""></el-table-column>
					<el-table-column prop="social_user.socNo" label="个人编号" width=""></el-table-column>
					<el-table-column prop="out_reason" label="减员原因" width=""></el-table-column>
					<el-table-column prop="out_time" label="减员时间" width=""></el-table-column>

				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import dayjs from 'dayjs'
	import page from "@/components/page/page.vue"; //分页  统一的

	export default {
		components: {
			page,
		},
		data() {
			return {
				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				forms: {
					name:'',
					idNo:'',
					socNo:'',
					month:'',
				},
				list: [],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				pickerOptions: {
					disabledDate(time) {
						console.log('time', time)
						return (time.getMonth()) > new Date().getMonth();
					}
				},
			 
			};
		},
		computed: {},
		watch: {},
		created() {
			this.page_title = this.$route.meta.title
			this.get_list()
		},
		mounted() {

		},
		methods: {
			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {

				console.log(this.forms)
				this.loading = true
				this.$api("socialLog", {
					// mobile:this.mobile
					...this.forms,
					...this.pages,
					
					time: this.month_select
				}, "post").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.list = res.data.list
					} else {
						alertErr(res.msg)
					}
				});
			},
		},
	};
</script>

<style scoped lang="less">
	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						margin-left: 20px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>